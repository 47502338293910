import React from 'react';
import styled from 'styled-components';
import {
  ContentfulRichTextGatsbyReference,
  renderRichText,
  RenderRichTextData,
} from 'gatsby-source-contentful/rich-text';
import { GatsbyImage } from 'gatsby-plugin-image';
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types';
import {
  ContentfulTopicCaseStudy,
  ContentfulTopicCaseStudyLongDescription,
  ContentfulTopicResourceCategory,
} from '@/../graphql-types';
import Typography from '@/components/layout/Typography';
import { ArrowDownward } from '@material-ui/icons';
import Button from '@/components/layout/Button';

const IframeContainer = styled.div`
  padding-bottom: 56.25%;
  position: relative;
  display: block;
  width: 100%;

  > iframe {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
`;

const Blockquote = styled.blockquote`
  font-size: ${(props) => props.theme.fontSize.h4};
  line-height: 1.3;
  margin-bottom: ${(props) => props.theme.spacing.xs};
  p {
    margin-bottom: 0;
  }
  em {
    font-size: 0.6em;
    color: ${(props) => props.theme.color.grey};
  }
`;

// to allow list items to override
const Paragraph = styled.p`
  margin-bottom:1.5em;
  }
`;
const Text = ({ children }) => <Paragraph>{children}</Paragraph>;
const UL_list = ({ children }) => (
  <ul className="list-disc list-outside ml-6 mb-6">{children}</ul>
);
const OL_list = ({ children }) => (
  <ol className="list-decimal list-outside ml-6 mb-6">{children}</ol>
);
const Heading_2 = ({ children }) => (
  <h2 className="text-primaryMain font-light text-3xl mb-4">{children}</h2>
);
const Heading_3 = ({ children }) => (
  <h3 className="text-primaryMain text-2xl mb-4">{children}</h3>
);
const Heading_4 = ({ children }) => (
  <p className="text-primaryMain font-bold text-xl mb-2">{children}</p>
);
// to handle contentful forcing <p> tags into <li>
const ListItemWrapper = styled.li`
  p {
    margin-bottom:0;
  }
}
`;
const ListItem = ({ children }) => (
  <ListItemWrapper>{children}</ListItemWrapper>
);

const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <strong>{text}</strong>,
    [MARKS.ITALIC]: (text) => <em>{text}</em>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    [BLOCKS.QUOTE]: (node, children) => <Blockquote>{children}</Blockquote>,
    [BLOCKS.UL_LIST]: (node, children) => <UL_list>{children}</UL_list>,
    [BLOCKS.OL_LIST]: (node, children) => <OL_list>{children}</OL_list>,
    [BLOCKS.LIST_ITEM]: (node, children) => <ListItem>{children}</ListItem>,
    [BLOCKS.HEADING_2]: (node, children) => <Heading_2>{children}</Heading_2>,
    [BLOCKS.HEADING_3]: (node, children) => <Heading_3>{children}</Heading_3>,
    [BLOCKS.HEADING_4]: (node, children) => <Heading_4>{children}</Heading_4>,
    [BLOCKS.HR]: (node, children) => <hr className="my-8 border-grey2" />,
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      const { gatsbyImageData } = node.data.target;
      if (!gatsbyImageData) {
        // asset is not an image
        return null;
      }
      return (
        <GatsbyImage
          alt={node?.data?.target?.title || 'image'}
          image={gatsbyImageData}
          className="my-sm"
        />
      );
    },
    [INLINES.HYPERLINK]: (node) => {
      if (node.data.uri.indexOf('youtube.com/embed') !== -1) {
        return (
          <IframeContainer>
            <iframe
              id="ytplayer"
              src={node.data.uri}
              width="640"
              height="360"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture ; fullscreen"
            />
          </IframeContainer>
        );
      } else return <a href={node.data.uri}>{node.content[0].value}</a>;
    },
    [INLINES.ASSET_HYPERLINK]: (node, children) => {
      return (
        <a
          href={node?.data?.target?.file?.url}
          rel="noreferrer"
          target="_blank"
        >
          {children}
        </a>
      );
    },
  },
  renderText: (text) =>
    text
      .replace(/\u2028/g, '')
      .split('\n')
      .flatMap((text, i) => [i > 0 && <br key={`break-${i}`} />, text]),
};

const Container = styled.div`
  a {
    color: ${(props) => props.theme.color.primaryMain};
  }
  a:hover,
  a:focus {
    color: ${(props) => props.theme.color.primaryDark};
  }
`;
const TitleContainer = styled.div`
  > *:first-child {
    padding-top: ${({ theme }) => theme.spacing.sm};
    margin-left: 0;
  }
  @media (min-width: ${(props) => props.theme.breakpoint.md}) {
    > *:first-child {
      margin-left: ${({ theme }) => theme.spacing.lg};
    }
  }
`;
const LinesWrapper = styled.div``;

interface RichBodyProps {
  title: string;
  content: ContentfulTopicRichLongDescription;
  titlePrefix: string;
  download: any;
  gated?: boolean;
}

const RichBody: React.FC<RichBodyProps> = ({
  title,
  content,
  titlePrefix,
  download = null,
  gated = false,
}) => {
  return (
    <Container className="flex-grow pr-0 md:pr-sm md:border-r border-gray-2 border-0">
      <div className="max-w-4xl m-auto relative">
        <LinesWrapper className="hidden md:block absolute top-0 left-0">
          <svg
            width="49"
            height="150"
            viewBox="0 0 49 150"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M25 2L25 104"
              stroke="#00A566"
              strokeWidth="3"
              strokeLinecap="round"
            />
            <line
              x1="1.5"
              y1="-1.5"
              x2="14.5"
              y2="-1.5"
              transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 24.5 133.215)"
              stroke="#00A566"
              strokeWidth="3"
              strokeLinecap="round"
            />
            <circle
              r="2"
              transform="matrix(1 -8.74228e-08 -8.74228e-08 -1 25.5 147.215)"
              fill="#00A566"
            />
            <path
              d="M47 64L47 2"
              stroke="#006DFF"
              strokeWidth="3"
              strokeLinecap="round"
            />
            <path
              d="M2 42L2 2"
              stroke="#FFB700"
              strokeWidth="3"
              strokeLinecap="round"
            />
          </svg>
        </LinesWrapper>
        <TitleContainer>
          <Typography
            as="h1"
            variant="h1"
            className="text-primaryMain font-light text-center md:text-left ml-0 mb-sm md:ml-lg md:mb-lg pt-sm pl-xs"
          >
            {/* {titlePrefix && titlePrefix + ':' + <br />} */}

            {title}
          </Typography>
          {download && (
            <div className="mb-sm md:hidden pt-sm text-center">
              <Button variant="Outline">
                <a
                  href={download?.file?.url}
                  target="_blank"
                  rel="noreferrer noopener"
                  className="whitespace-nowrap"
                >
                  <span>Download PDF</span>
                  <ArrowDownward className="ml-xs" />
                </a>
              </Button>
            </div>
          )}
        </TitleContainer>
        <div>{renderRichText(content, options)}</div>
      </div>
    </Container>
  );
};
export default RichBody;
