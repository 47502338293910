import React from 'react';
import styled from 'styled-components';
import {
  ContentfulComponentBackground,
  ContentfulPagePressRelease,
  ContentfulEntry,
} from '@/../graphql-types';
import RichBody from '../components/RichBody';
import BackgroundWrapper from '@/components/BackgroundWrapper'; //helper for background css on other components
// import {
//   Undefined,
// } from '@/components/contentful/components';
// import * as Constants from '@/types/Constants';

type BackgroundComponent = ContentfulEntry & {
  background: ContentfulComponentBackground;
};

const Container = styled.div``;

interface PagePressReleaseProps {
  data?: ContentfulPagePressRelease;
}
const PagePressRelease: React.FC<PagePressReleaseProps> = (props) => {
  const { data } = props;

  // if the component has a background, then wrap it with a background
  const wrapBackground = (component, element) => {
    const hasBackground = component?.['background'] != null;
    if (hasBackground) {
      return (
        <BackgroundWrapper
          key={`background-component-${component.id}`}
          data={(component as BackgroundComponent)?.background}
        >
          {element}
        </BackgroundWrapper>
      );
    } else {
      return element;
    }
  };

  return (
    <Container className="container md:flex gap-x-md max-w-xxl mx-auto mb-sm">
      <RichBody
        title={data?.title}
        titlePrefix=""
        gated={null}
        content={data?.body}
        download={null}
      />
    </Container>
  );
};
export default PagePressRelease;
