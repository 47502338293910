import React from 'react';
import { graphql, PageProps } from 'gatsby';
import {
  ContentfulComponentSiteFooter,
  ContentfulComponentSiteHeader,
  ContentfulComposePage,
  ContentfulComposeSeo,
  ContentfulPagePressRelease,
  PressReleasePageQuery,
} from '@/../graphql-types';
import Layout from '@/components/layout/Layout';
import PagePressRelease from '@/components/contentful/pages/PressRelease';
import SEO from '@/components/SEO';
import * as Constants from '@/types/Constants';

export const query = graphql`
  query PressReleasePage($id: String!, $node_locale: String!) {
    contentfulComponentSiteHeader(
      node_locale: { eq: $node_locale }
      name: { eq: "Miovision.com - Site Header" }
    ) {
      ...ContentfulComponentSiteHeaderFragment
    }
    contentfulComponentSiteFooter(
      node_locale: { eq: $node_locale }
      name: { eq: "Miovision.com - Site Footer" }
    ) {
      ...ContentfulComponentSiteFooterFragment
    }
    contentfulComposePage(id: { eq: $id }, node_locale: { eq: $node_locale }) {
      id
      internal {
        type
      }
      slug
      title
      seo {
        createdAt
        updatedAt
        description
        id
        keywords
        pageType
        no_follow
        no_index
        title
        canonicalUrl
        featuredImage {
          description
          file {
            url
          }
        }
      }
      content {
        ... on ContentfulPagePressRelease {
          id
          internal {
            type
          }
          title
          publishDate(formatString: "MMM DD, YYYY")
          body {
            raw
            references {
              ... on ContentfulAsset {
                ...ContentfulAssetFragment
              }
            }
          }
        }
      }
    }
  }
`;

const Page: React.FC<PageProps<PressReleasePageQuery>> = (props) => {
  const { location, data } = props;
  const pageHeader =
    data?.contentfulComposePage?.content?.internal?.type === Constants.PAGE_FLEX
      ? data?.contentfulComposePage?.content?.siteHeader
      : data?.contentfulComponentSiteHeader;
  const pageFooter =
    data?.contentfulComposePage?.content?.internal?.type === Constants.PAGE_FLEX
      ? data?.contentfulComposePage?.content?.siteFooter
      : data?.contentfulComponentSiteFooter;

  return (
    <>
      <SEO
        location={location}
        defaultPageType={data?.contentfulComposePage?.content?.internal?.type}
        defaultTitle={data?.contentfulComposePage?.title}
        seo={data?.contentfulComposePage?.seo as ContentfulComposeSeo}
      />
      <Layout
        location={location}
        page={data?.contentfulComposePage as ContentfulComposePage}
        header={pageHeader as ContentfulComponentSiteHeader}
        footer={pageFooter as ContentfulComponentSiteFooter}
      >
        <PagePressRelease
          data={
            data?.contentfulComposePage?.content as ContentfulPagePressRelease
          }
        />
      </Layout>
    </>
  );
};

export default Page;
